import styled from "styled-components"

const SidebarContainer = styled.nav`
  align-self: flex-start;
  width: 222px;
  height: 95vh;
  padding: 24px;
  margin: 0;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
`

export default SidebarContainer
