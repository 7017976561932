import React from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useGetUserNotificationsQuery } from "../../store/slice/api";
import IconButton from "./IconButton";

const Notification = ({backgroundColor, color}) => {
    const navigate = useNavigate()
    const { data: notifications } = useGetUserNotificationsQuery()

    return (
        <>
            <IconButton backgroundColor={backgroundColor ? backgroundColor : ''} style={{ position: "relative" }} onClick={() => { navigate('/home/notifications') }}>
                {notifications?.results.some(n => !n.is_read) &&
                    <div style={{ position: 'absolute', backgroundColor: '#ff5f5f', borderRadius: '100%', border: '2px solid white', width: '10px', height: '10px', right: "18px", top: '13px' }}></div>
                }
                <IoNotificationsOutline color={color ? color: ''} />
            </IconButton>
        </>
    )
}

export default Notification