// Auth
export const SET_USER = "SET_USER"
export const CLEAR_USER = "CLEAR_USER"
export const AUTH_ERROR = "AUTH_ERROR"
export const SET_SIGNUP = "SET_SIGNUP"
export const SET_SOCIALLOGIN = "SET_SOCIALLOGIN"



// Reset Password
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"

// Change Password
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"

// Buddies
export const ADD_BUDDY_REQUEST = "ADD_BUDDY_REQUEST"
export const ADD_BUDDY_SUCCESS = "ADD_BUDDY_SUCCESS"
export const ADD_BUDDY_ERROR = "ADD_BUDDY_ERROR"
