import styled from "styled-components"

const Title = styled.p`
  width: ${props => props.width ?? "auto"};
  font-family: "TT Commons";
  font-style: normal;
  font-weight: ${props => props.fontWeight ?? "500"};
  font-size: ${props => props.fontSize ?? "32px"};
  line-height: ${props => props.lineHeight ?? "1.1"};
  text-align: ${props => props.textAlign ?? "center"};
  color: #000000;
  margin: ${props => props.margin ?? "0"};
  display: ${props => props.display ?? "block"};
  overflow: ${props => props.overflow ?? "visible"};
  text-overflow: ${props => props.textOverflow ?? "unset"};
`

export default Title
