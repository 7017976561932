import React, { useState, useEffect, useMemo } from "react"
import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { useModal } from "../../../context/modal-context"
import { Paragraph } from "../../common"
import AddOnsModal from "../AddOnsModal"
import Container from "./Container"
import Coupon from "./Coupon"
import PlanButton from "./PlanButton"
import PlanFeature from "./PlanFeature"
import PlanFeatures from "./PlanFeatures"
import PlanHeader from "./PlanHeader"
import PlanName from "./PlanName"
import PlanPrice from "./PlanPrice"
import { useSelector } from "react-redux"
import { useVerifyPromotionCodeMutation } from "../../../store/slice/api"

export default function Plan({ plan: { id: planId, nickname: name, unit_amount, currency, color, availableFeatureCount, payment, height, features }, videos, storages, interval, monthly }) {
  const navigate = useNavigate()
  const [price, setPrice] = useState(unit_amount)
  const [coupon, setCoupon] = useState('')
  const { setModal, unSetModal } = useModal()
  const user = useSelector(state => state.reducer.auth.user)
  const [verifyPromoCode] = useVerifyPromotionCodeMutation()

  useEffect(() => {
    setPrice(unit_amount)
    return () => setCoupon('')
  }, [unit_amount])

  const subscribe = useMemo(() => {
    if (user && user.subscription) {
      return user.subscription.items.find((item) => {
        if (item.plan.nickname.includes('package') && item.plan.id === planId) {
          return item
        } else {
          return
        }
      })
    }
  }, [user, interval, monthly])

  const swapId = useMemo(() => {
    if (!(user?.subscription?.items?.length)) return undefined

    return user.subscription.items.find((item) => item.plan.nickname.includes('package'))?.id
  }, [user, interval, monthly])

  const choosePlan = async ({ storage, video }) => {
    const state = {
      plan: {
        id: planId,
        nickname: name,
        unit_amount: price,
        currency,
        color,
        features,
        swapId: swapId ?? ''
      },
      storage,
      video,
    }

    try {
      if (coupon && !Boolean(swapId)) {
        const res = await verifyPromoCode(coupon).unwrap()
        const promo = res.data[0];
  
        if (!promo) {
          alert("Invalid coupon code")
        } else if (!promo.active) {
          alert('Coupon you have provided is not active')
        } else if (promo.coupon.amount_off) {
          state.plan.unit_amount = price - (promo.coupon.amount_off / 100);
          state.plan.promotion_code = promo.id;
        } else if (promo.coupon.percent_off) {
          state.plan.unit_amount = price - (price * promo.coupon.percent_off / 100);
          state.plan.promotion_code = promo.id;
        } else {
          console.log("Both amount_off and percent_off are null")
        }
      }

      navigate('/subscriptions/payment', { state })
    } catch (err) {
      console.log(err)
      alert("Some error occurred")
    }
  }

  const buyAddon = async ({ storage, video }) => {
    unSetModal()
    await choosePlan({ storage, video })
  }

  const choosePlanSubscribed = (e) => {
    window.alert('plan already Subscribed')
  }

  const viewAddon = () => {
    setModal(<AddOnsModal subscribe={subscribe} storages={storages} videos={videos} nickname={name} subscription={price} currency={currency} color={color} availableFeatureCount={availableFeatureCount} buy={buyAddon} />)
  }

  return (
    <Container>
      <PlanHeader>
        <PlanName color={color}>{name}</PlanName>
        <PlanPrice>{currency}{price}</PlanPrice>
        <Paragraph width="" color="#000">For single membership</Paragraph>
      </PlanHeader>

      <PlanFeatures width="279px">
        {features.map((v, i) => (
          <PlanFeature key={i} active={true}>
            <IoCheckmarkCircleOutline size="20px" color="#00A652" style={{ marginRight: "5px" }} />{v}
          </PlanFeature>
        ))}
      </PlanFeatures>

      {!payment &&
        <>
          {!Boolean(swapId) && <Coupon placeholder="Coupon Code" value={coupon} onChange={(e) => setCoupon(e.target.value)} />}
          {!subscribe && <PlanButton onClick={choosePlan}>Choose plan</PlanButton>}
          {subscribe && <PlanButton background='#00A652' color='#fff' border='none' onClick={choosePlanSubscribed}>Subscribed</PlanButton>}
          <Paragraph bold link color="#00A652" onClick={viewAddon}>View Add-ons</Paragraph>
        </>
      }
    </Container>
  )
}
