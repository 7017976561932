import React, { useMemo, useState } from "react"
import styled from "styled-components"

import { IoDocumentOutline, IoDocumentTextOutline, IoEllipsisHorizontalOutline, IoImageOutline, IoMusicalNoteOutline, IoNotificationsOutline } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { logout } from "../../store/actions/auth"
import people from "../../assets/images/people.png"
import SignOut from "../../assets/images/SignOut.png"
import Group86 from "../../assets/images/Group 86.png"
import NotePencil from "../../assets/images/NotePencil.png"
import Avatar from '../../assets/images/avatar.png'
import Grid from '../../assets/images/grid.svg'
import List from '../../assets/images/list.svg'

import OptionMenuSettings from "../../components/common/OptionMenuSettings"
import BackTransactions from "../TransactionsPaymentHistory/BackTransactions"
import folder from "../../assets/images/folder.png"
import { Button, IconButton, OptionsMenu, Row, Title, InputGroup, Paragraph, Column } from "../../components/common"
import moment from 'moment'
import {
  Table,
  TableHeadRow,
  TableBodyRow
} from "../../components/MyBuddies"
import FolderContainer from "../Layout/pages/Home/FolderContainer"
import { useDeleteFileMutation, useGetBuddiesQuery, useGetBuddyProfileQuery, useGetSharedFileAccessQuery, useGetSharedFolderAccessQuery, useGetSharedFoldersFilesQuery } from "../../store/slice/api"
import { truncateString } from "../../utils/functions"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import LoadingSpinner from "../../components/common/LoadingSpinner"
import { useEffect } from "react"
import Notification from "../../components/common/Notification"
import { BASE_URL } from "../../constants"

const Text = styled.span`
  font-family: "TT Commons";
  font-style: 'normal';
  font-size: 16px;
  font-weight: ${props => props.fontWeight || 500};
  color: black;
  line-height: 30px;
  margin-bottom:0
`
const Heading = styled.span`
  font-family: "TT Commons";
  font-style: 'normal';
  font-size: 20px;
  color: black;
  margin: auto 0;
  font-weight: 600;
  line-height: 30px
`

export default function Shared() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showShared, setShowShared] = useState(false)
  const [border, setBorder] = useState()
  const [gridView, setGridView] = useState(true)
  const [getBuddy, setBuddy] = useState()
  const [date, setDate] = useState()
  const [accessGiven, setAccessGiven] = useState()
  const { data: sharedBuddies, isLoading: isSharedBuddiesLoading, } = useGetSharedFoldersFilesQuery()
  const { data: myBuddies } = useGetBuddiesQuery()
  const token = useSelector(state => state.reducer.auth.token)

  const Shared = useMemo(() => {
    if (sharedBuddies) {
      return [...sharedBuddies.shared_files, ...sharedBuddies.shared_folders]
    }
  }, [sharedBuddies])

  const allowedAccessCommonBuddies = useMemo(() => {
    if (myBuddies && accessGiven) {
      return myBuddies.filter((bud) => {
        return accessGiven.find((acc) => (bud.buddydetail?.id === acc))
      })
    }
  }, [myBuddies, accessGiven])

  const allowAccessNotCommonBuddies = useMemo(() => {
    if (!allowedAccessCommonBuddies?.length) return accessGiven
    if (allowedAccessCommonBuddies && accessGiven) {
      return accessGiven.filter((acc) => {
        return allowedAccessCommonBuddies.find((allow) => (acc != allow.buddydetail?.id))
      })
    }
  }, [allowedAccessCommonBuddies, accessGiven, getBuddy])

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = [];
      for (let i = 0; i < allowAccessNotCommonBuddies.length; i++) {
        const response = await fetch(`${BASE_URL}/users/profile/${allowAccessNotCommonBuddies[i]}/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
          }
        });
        const data = await response.json();
        fetchedData.push(data);
      }
      setBuddy(fetchedData);
    };
  
    if (allowAccessNotCommonBuddies?.length) {
      fetchData();
    }
  }, [allowAccessNotCommonBuddies])  
  
  const buddyWithProfile = useMemo(() => {
    if (getBuddy?.length && allowAccessNotCommonBuddies?.length) {
      return getBuddy.filter((item) => {
        return allowAccessNotCommonBuddies.find((all) => {
          if (item.id == all) return all
        })
      })
    }
  }, [getBuddy, allowAccessNotCommonBuddies])

  const buddyWithOutProfile = useMemo(() => {
    if (buddyWithProfile?.length && allowAccessNotCommonBuddies?.length) {
      return allowAccessNotCommonBuddies.filter((item) => {
        return buddyWithProfile.find((all) => {
          if (item !== all.id) return item
        })
      })
    }
  }, [buddyWithProfile, allowAccessNotCommonBuddies, border])

  const getSizeOfFolder = (item) => {
    return  item?.files?.reduce((accumulator, currentValue) => {
      const size = accumulator + currentValue?.size
      return size ;
    }, 0);
  }


  if (isSharedBuddiesLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Row
        width="100%"
        height="73px"
        padding="24px 0"
        alignItems="center"
        justifyContent="space-between"
      >
        <BackTransactions />
        <Title margin='0px 0px 0px 80px'>Shared From Buddies</Title>

        <Row>
          <IconButton onClick={() => { setGridView(!gridView) }}>
            <img src={gridView ? Grid : List} />
          </IconButton>
          <OptionMenuSettings
            options={[
              { Icon: people, text: "My Buddies", onClick: () => { navigate('/home/my-buddies') } },
              { Icon: Group86, text: "My transactions", onClick: () => { navigate('/home/transactions') } },
              { Icon: NotePencil, text: "Send Feedback", onClick: () => { navigate('/home/send/feedback') } },
              {
                Icon: SignOut, text: "Logout", onClick: () => {
                  dispatch(logout())
                  navigate("/")
                }
              }
            ]}
          />
          <Notification />
        </Row>
      </Row>

      {!Boolean(Shared?.length) && gridView &&
        <div style={{ width: '100%', height: '100vh', textAlign: 'center', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
          <h1 style={{ fontWeight: 400, fontSize: '24px' }}>Shared files and folders will be displayed here</h1>
        </div>
      }

      {!gridView
        ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Row width='70%' style={{ height: 'fit-content' }}>
              <Table style={{ width: '100%' }}>
                <thead>
                  <TableHeadRow hideBottomBorder>
                    <th>Name</th>
                    <th>Shared by</th>
                    <th>Size</th>
                    <th>Shared date</th>
                  </TableHeadRow>
                </thead>
                <tbody>
                  {
                    Shared?.map((items, index) => {
                      return (
                        <TableBodyRow share={index === border} shared='shared' onClick={() => { setBorder(index); setShowShared(true); setAccessGiven(items.access_given_to ? items.access_given_to : items.folder_access_given_to); setDate(items.uploaded_on ? items.uploaded_on : items.shared_date) }}>
                          <td style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <Row alignItems={'end'} >
                              {items.file ? <> {
                                items.extension?.includes('application')
                                  ? <IoDocumentTextOutline color='#00A652' size='18px' />
                                  : items?.extension?.includes('image')
                                    ? <IoImageOutline color='#FF5F5F' size='18px' />
                                    : items?.extension?.includes('music')
                                      ? <IoMusicalNoteOutline color='#1877F2' size='18px' />
                                      : <IoDocumentOutline color='#00A652' size='18px' />}</> : <img width="18px" height="18px" src={folder} />
                              }
                            </Row>
                            <p>{truncateString(items?.name)}</p>
                          </td>
                          <td style={{ padding: '18px' }}>{items?.shared_by}</td>
                          {!items.size ? <td style={{ padding: '18px' }}>{getSizeOfFolder(items) < 1 ? (getSizeOfFolder(items) * 1024)?.toFixed(2) + 'KBs' : getSizeOfFolder(items).toFixed(2) + "MBs"} </td>
                            :
                            <td style={{ padding: '18px' }}>{items?.size < 1 ? (items?.size * 1024)?.toFixed(2) + ' KBs' : items?.size?.toFixed(2) + ' Mbs'}</td>
                          }
                          <td style={{ padding: '18px' }}>{moment(items?.shared_date)?.format('L')}</td>
                          <td>
                            <OptionsMenu
                              options={[
                                { isAvailable: true, text: "Open", onClick: () => { items.file ? window.open(items.file) : navigate(`/home/documents/folder/${items.id}`) } },
                                { isAvailable: false, text: "Rename" },
                                { isAvailable: false, text: "Delete" },
                                { isAvailable: false, text: "Make a Copy" }
                              ]}
                              alignment='true'
                            />
                          </td>
                        </TableBodyRow>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Row>

            {Boolean(showShared) &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '290px' }}>
                <Row>
                  <div style={{ display: 'flex', flexDirection: 'column', }}>
                    <Box width='100%' height='70vh' sx={{ boxShadow: '0.5px 0.5px 0.5px #f5f5f5', borderRadius: '15px', paddingX: '20px', paddingY: '10px', border: '1px solid #f5f5f5', backgroundColor: 'white', overflowY: 'auto' }}>
                      <Heading>Details</Heading>
                      <hr style={{ border: 'none', borderTop: '1px solid rgba(0, 0, 0, 0.05)' }} />
                      <Text fontWeight='600'>Uploaded on: {moment(date)?.format('L')}</Text>
                      <Box sx={{ marginTop: '10px' }}>
                        <Text fontWeight='600'>Shared with</Text>
                      </Box>

                      {
                        Boolean(allowedAccessCommonBuddies?.length) && allowedAccessCommonBuddies.map((item) => {
                          return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }} className="country-option">
                              <div style={{ width: '30px', height: '30px', borderRadius: '50%', overflow: 'hidden' }}>
                                <img style={{ width: "100%", height: '100%', objectFit: 'cover', objectPosition: 'center' }} src={Avatar} alt='Profile picture' />
                              </div>
                              <Text>{item.buddydetail.username}</Text>
                            </div>
                          )
                        })
                      }
                      {
                        Boolean(buddyWithProfile?.length) && buddyWithProfile.map((item) => {
                          return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }} className="country-option">
                              <div style={{ width: '30px', height: '30px', borderRadius: '50%', overflow: 'hidden' }}>
                                <img style={{ width: "100%", height: '100%', objectFit: 'cover', objectPosition: 'center' }} src={item.profile_picture ? item.profile_picture : Avatar} alt='Profile picture' />
                              </div>
                              <Text>{item.name}</Text>
                            </div>
                          )
                        })
                      }
                      {
                        Boolean(buddyWithOutProfile?.length) && buddyWithOutProfile.map((item) => {
                          return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }} className="country-option">
                              <div style={{ width: '30px', height: '30px', borderRadius: '50%', overflow: 'hidden' }}>
                                <img style={{ width: "100%", height: '100%', objectFit: 'cover', objectPosition: 'center' }} src={Avatar} alt='Profile picture' />
                              </div>
                              <Text>{item}</Text>
                            </div>
                          )
                        })
                      }
                    </Box>
                  </div>
                </Row>
              </div>
            }
          </div>
        : <div style={{ display: "flex" }}>
            <GridView Shared={Shared} setShowShared={setShowShared} setAccessGiven={setAccessGiven} setDate={(value) => setDate(value)} />
            {Boolean(showShared) &&
              <div style={{ width: '290px', display: 'flex', justifyContent: 'center' }}>
                <Row >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Box width='100%' height='70vh' sx={{ boxShadow: '0.5px 0.5px 0.5px #f5f5f5', borderRadius: '15px', paddingX: '20px', paddingY: '10px', border: '1px solid #f5f5f5', backgroundColor: 'white', overflowY: 'auto' }}>
                      <Heading>Details</Heading>
                      <hr style={{ border: 'none', borderTop: '1px solid rgba(0, 0, 0, 0.05)' }} />
                      <Text fontWeight='600'>Uploaded on: {moment(date)?.format('L')}</Text>
                      <Box sx={{ marginTop: '10px' }}>
                        <Text fontWeight='600'>Shared with</Text>
                      </Box>
                      {
                        Boolean(allowedAccessCommonBuddies?.length) && allowedAccessCommonBuddies.map((item) => {
                          return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }} className="country-option">
                              <div style={{ width: '30px', height: '30px', borderRadius: '50%', overflow: 'hidden' }}>
                                <img style={{ width: "100%", height: '100%', objectFit: 'cover', objectPosition: 'center' }} src={Avatar} alt='Profile picture' />
                              </div>
                              <Text>{item.buddydetail.username}</Text>
                            </div>
                          )
                        })
                      }
                      {
                        Boolean(buddyWithProfile?.length) && buddyWithProfile.map((item) => {
                          return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }} className="country-option">
                              <div style={{ width: '30px', height: '30px', borderRadius: '50%', overflow: 'hidden' }}>
                                <img style={{ width: "100%", height: '100%', objectFit: 'cover', objectPosition: 'center' }} src={item.profile_picture ? item.profile_picture : Avatar} alt='Profile picture' />
                              </div>
                              <Text>{item.name}</Text>
                            </div>
                          )
                        })
                      }
                      {
                        Boolean(buddyWithOutProfile?.length) && buddyWithOutProfile.map((item) => {
                          return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }} className="country-option">
                              <div style={{ width: '30px', height: '30px', borderRadius: '50%', overflow: 'hidden' }}>
                                <img style={{ width: "100%", height: '100%', objectFit: 'cover', objectPosition: 'center' }} src={Avatar} alt='Profile picture' />
                              </div>
                              <Text>{item}</Text>
                            </div>
                          )
                        })
                      }


                      {/* <Button
                      width="150px"
                      height="46px"
                      color="#00A652"
                      margin='20px 0 0 0'
                    >
                      Download
                    </Button> */}
                    </Box>
                  </div>
                </Row>
              </div>
            }
          </div>
      }
    </>
  )
}

const GridView = ({ setShowShared, Shared, setAccessGiven, setDate }) => {
  const [border, setBorder] = useState()
  const navigate = useNavigate()

  return (
    <Box width='76%' height='200px' sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {
        Shared?.map((item, index) => {
          return (
            <div onClick={() => { setBorder(index); setShowShared(true); setAccessGiven(item.access_given_to ? item.access_given_to : item.folder_access_given_to); setDate(item.uploaded_on ? item.uploaded_on : item.shared_date) }} >
              <FolderContainer
                key={index}
                width='150px'
                height='150px'
                flexDirection='column'
                justifyContent='flex-start'
                alignItems='flex-start'
                padding='10px'
                margin='10px'
                borderRadius='15px'
                isSelected={index === border}
              >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <OptionsMenu
                    color="rgba(0, 0, 0, 0.4)"
                    orientation="horizontal"
                    options={[
                      { isAvailable: true, text: "Open", onClick: () => { item.file ? window.open(item.file) : navigate(`/home/documents/folder/${item.id}`) } },
                      { isAvailable: false, text: "Rename" },
                      { isAvailable: false, text: "Delete" },
                      { isAvailable: false, text: "Make a Copy" }
                    ]}
                    alignment='true'
                  />
                </div>
                <Column justifyContent={'flex-end'} style={{ width: "100%" }}>
                  <Row alignItems={'center'} margin='0px 0px 15px' style={{ width: "100%" }}>
                    {item?.file ? <> {
                      item?.extension?.includes('application')
                        ? <IoDocumentTextOutline color='#00A652' size='18px' />
                        : item?.extension?.includes('image')
                          ? <IoImageOutline color='#FF5F5F' size='18px' />
                          : item?.extension?.includes('music')
                            ? <IoMusicalNoteOutline color='#1877F2' size='18px' />
                            : <IoDocumentOutline color='#00A652' size='18px' />}</> : <img width="18px" height="18px" src={folder} />
                    }
                    <Title fontSize='10px' margin='0px 10px' textAlign="start" width="calc(100% - 28px)" overflow="hidden" textOverflow="ellipsis">{truncateString(item?.name)}</Title>
                  </Row>
                </Column>
              </FolderContainer>
            </div>
          )
        })
      }
    </Box>
  )
}
