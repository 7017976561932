import React from 'react'
import { Back, Container, Page, Row, Title, Paragraph } from "../../components/common"

const FAQ = () => {
  return (
    <Page>
      <Container width="1227px" margin="69px auto" justifyContent="flex-start">
        <Title fontWeight="700" margin="46px auto 0">
          FAQ's
        </Title>
        <div style={{ marginTop: 60 }}></div>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  What is S&S Vault?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span>  Store and Share Vault is an online secure central location where individuals (Prime Account Holder) can store and share personal and confidential information and documents with up to four people known as Buddies.</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  Why should I use S&S Vault?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span>  At Store and Share Vault we believe our product and services will help everyone, whether you are 25 years old and just starting to put your life together, 50 years old in a terminal situation wishing to ease the suffering of loved ones or 80 years old preparing to ease the transition for your family this product is for you.</p>
        <p style={{ fontWeight: 'bold', textAlign: 'start', width: '900px', marginBottom: '0px', marginBottom: '0px' }}>
          Q:  Who are Store and Share Vault users?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span>Everyone.  If you have online accounts, documents, information and files family or loved ones need to have access to should anything happen to you, Store and Share Vault is for you.</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  How do I know my information is secure on your platform?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span> Store and Share Vault uses bank level encryption.  We use Amazon Web Services S3 Encryption.  </p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  What other services does Store and Share Vault offer?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span>  At this moment S&S Vault only offers the portal for you to upload, share and manage information, document and files.  In the near future we will offer other services through our partners.  Some of the services we will be offering are financial planning, tips on entrepreneurship, living will declaration, power of attorney’s, mental health and substance abuse assistance and other services related to personal and family growth.  You will have access to all of these services at your current monthly subscription cost.</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  What is a Buddy?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span>A Buddy is an individual who is invited by the Prime Account Holder to be one of the individuals they can share their information with.  A Buddy can also be a Prime Account Holder.</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>

          Q:  What is a Sub-Prime Account Holder?

        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span> A Buddy is an individual who is invited by the Prime Account Holder to be one of the individuals they can share their information with.  A Buddy can also be a Prime Account Holder.</p>

        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  Can I remove a buddy at anytime?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span> Yes.  But if you are removing Buddy #1 who is also known as the Sub-Prime Account Holder who will be the one receiving all of your shared information if something were to happen to you.  If this happens your Buddy #2 will become Buddy #1.</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  What is time interval for notification of account release?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span>  Intervals for notification of account release start at one week and goes up to one month.</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  What happens if something happens to the Prime Account Holder?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span>  If the Prime Account Holder were to become unavailable, incapacitated or deceased and fails to verify their account within the allotted time set forth in their notification of account release, all information that was set for timed release will be automatically sent to the Buddy or their choosing or if no Buddy was chosen, that information will be released to the Sub-Prime Account Holder/Buddy #1..</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  How does sharing my stored information with Buddies work?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span> You can share a specific document, file and information with a specific buddy.  Once you have stored your documents, files and information, you select the buddy you want to share that piece of information with.  You will then be able to share this information with them immediately or after a specified amount of time.</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  Can I share the same information with multiple Buddies?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span> You can share the same information and documents with multiple Buddies.</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'start', width: '900px', marginBottom: '0px' }}>
          Q:  Can I remove a buddy at anytime?
        </p>
        <p style={{ textAlign: 'start', width: '900px', marginBottom: '0px' }}><span style={{ fontWeight: 'bold', marginBottom: '0px' }}>A:</span> Yes.  But if you are removing Buddy #1 who is also known as the Sub-Prime Account Holder who will  receiving all of your shared information not shared with anyone if something were to happen to you and your Buddy #2 will become Buddy #1.</p>

        <div style={{ marginTop: 60 }}></div>
        <Back />
      </Container>
    </Page>
  )
}

export default FAQ