import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment"

import { Brand, CustomLink, Paragraph, Row, Button, Page, Title } from "../../components/common";
import { NavBar, Ellipse1, Ellipse2, Footer, BottomSection } from "../../components/Landing";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg"
import { ReactComponent as Calender } from "../../assets/images/calender.svg"
import blogPlaceholder from "../../assets/images/blog-placeholder.png"
import { useGetBlogsQuery } from "../../store/slice/api";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const Blog = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const { data: blogs, isLoading: isBlogLoading, } = useGetBlogsQuery()

    const navigate = useNavigate()

    const handleAboutUsClick = () => {
        navigate('/about')
    };

    const handleHowItWorksClick = () => {
        navigate('/how-it-works')
    }

    const handleProductsClick = () => {
        navigate('/products')
    }

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    }

    return (
        <Page style={{ minHeight: '100vh' }} height="100%">
            <Ellipse1 />
            <Ellipse2 />
            <NavBar style={{ position: 'relative' }}>
                <Row link alignItems="center">
                    <Logo />
                    <Brand>S & S Vault</Brand>
                </Row>
                <Row margin="auto 0 auto 200px">
                    <Paragraph onClick={() => navigate('/')} cursor={'pointer'} color="#02271480" fontSize="18px" margin="auto 32px">
                        Home
                    </Paragraph>
                    <Paragraph onClick={handleAboutUsClick} cursor={'pointer'} color="#02271480" fontSize="18px" margin="auto 32px">
                        About us
                    </Paragraph>
                    <Paragraph onClick={handleProductsClick} cursor={'pointer'} color="#02271480" fontSize="18px" margin="auto 32px">
                        Products
                    </Paragraph>
                    <Paragraph onClick={handleHowItWorksClick} cursor={'pointer'} color="#02271480" fontSize="18px" margin="auto 32px">
                        How it works
                    </Paragraph>
                    <Paragraph onClick={() => navigate('/blog')} cursor={'pointer'} color="#022714" fontSize="18px" margin="auto 32px">
                        Blog
                    </Paragraph>
                </Row>
                <Row>
                    <CustomLink
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        to="/login"
                    >
                        <Paragraph color="#022714" fontSize="18px" margin="auto 32px">
                            Login
                        </Paragraph>
                    </CustomLink>

                    <CustomLink
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        to="/signup"
                    >
                        <Button
                            color="#00A652"
                            width="104px"
                            height="48px"
                            borderRadius="8px"
                        >
                            Sign Up
                        </Button>
                    </CustomLink>
                </Row>
            </NavBar>
            {
                isBlogLoading
                    ? <LoadingSpinner />
                    : blogs.count < 1 ?
                        <Title fontWeight="700" fontSize="65px" margin="56px 0 0" style={{ width: '100%', maxWidth: '700px' }}>
                            No Blog Found
                        </Title>
                        : <>
                            <Title fontWeight="700" fontSize="65px" margin="56px 0 0" style={{ width: '100%', maxWidth: '700px' }}>
                                Knowledge to Power your industry.
                            </Title>
                            <Paragraph color="#02271480" fontSize="20px" margin="40px 0 0" style={{ width: '100%', maxWidth: '900px' }}>
                                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                            </Paragraph>
                            <Row justifyContent="center" margin="70px 0 40px" style={{ gap: '47px', flexWrap: 'wrap', maxWidth: "1130px" }}>
                                {
                                    blogs?.results?.map((items) => {
                                        return (
                                            <div style={{ width: '345px', height: 'auto', backgroundColor: "#fff", cursor: 'pointer', borderRadius: '5px', overflow: 'hidden' }} onClick={() => navigate(`/blog/${items.id}`)}>
                                                <div style={{ width: '400px', height: '230px' }}>
                                                    {items?.picture
                                                        ?   <img src={items.picture} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: "center" }} />
                                                        :   items?.video
                                                            ?   <video
                                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                                    src={items.video}
                                                                    controls={true}
                                                                    autoPlay={false}
                                                                    onPlay={handlePlayPause}
                                                                    onPause={handlePlayPause}
                                                                />
                                                            :   <img src={blogPlaceholder} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: "center" }} />

                                                    }

                                                </div>
                                                <div style={{ padding: '0 15px 15px', }}>
                                                    <h2 style={{ margin: "18px 0 0", font: "700 18px/27.5px Montserrat", color: "#022714" }}>{items?.headline}</h2>
                                                    <p style={{ 
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        WebkitLineClamp: 4,
                                                        marginTop: "10px",
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        font: "400 16px/26px TT Commons"
                                                    }}>
                                                        {items?.text}
                                                    </p>
                                                    <div style={{ marginTop: "10px", display: "flex", alignItems: 'center', gap: "5px", font: "400 14px/19.6px TT Commons" }}>
                                                        <Calender /> <span style={{ color: 'rgba(0,0,0,0.5)' }}>{moment(items.created_at).format('L')}</span>
                                                    </div>
                                                    {Boolean(items.category?.length) &&
                                                        <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                                                            {items.category.split(',').map(c => (
                                                                <span style={{ borderRadius: "2px", padding: "5px 8px", backgroundColor: "#00A65221", font: "400 15px/15px TT Commons" }}>{c}</span>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </Row>
                        </>
            }

            <BottomSection />
            <Footer>©2022 All Rights Reserved by S & S Vault</Footer>
        </Page>
    )
}
export default Blog