import styled from "styled-components"

const MenuContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: ${props => props.alignment ? '-150px' : props.isTable? '-80px' : '-50px'};
  right: -20px;

  z-index: 50;

  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  overflow: hidden;
`

export default MenuContainer
