import styled from "styled-components"

const Label = styled.p`
  font-family: "TT Commons";
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 5px 0;
  color: #000000;
`

export default Label
