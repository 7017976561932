import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment"

import { Brand, CustomLink, Paragraph, Row, Button, Page } from "../../components/common";
import { NavBar, Ellipse1, Ellipse2, Footer, BottomSection } from "../../components/Landing";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg"
import { ReactComponent as Calender } from "../../assets/images/calender.svg"
import blogPlaceholder from "../../assets/images/blog-placeholder.png"
import { useGetBlogsQuery } from "../../store/slice/api";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const ViewBlog = () => {
    const params = useParams();
    const { data: blogs, isLoading: isBlogLoading, } = useGetBlogsQuery()
    const navigate = useNavigate()

    const handleAboutUsClick = () => {
        navigate('/about')
    };

    const handleHowItWorksClick = () => {
        navigate('/how-it-works')
    }

    const handleProductsClick = () => {
        navigate('/products')
    }

    const blog = useMemo(() => {
        if (params.id && blogs) {
            return blogs.results.find((b) => (b.id === params.id))
        }
        return undefined;
    }, [params, blogs])

    return (
        <Page style={{ minHeight: '100vh' }} height='100%'>
            <Ellipse1 />
            <Ellipse2 />
            <NavBar style={{ position: 'relative' }}>
                <Row link alignItems="center">
                    <Logo />
                    <Brand>S & S Vault</Brand>
                </Row>
                <Row margin="auto 0 auto 200px">
                    <Paragraph onClick={() => navigate('/')} cursor={'pointer'} color="#02271480" fontSize="18px" margin="auto 32px">
                        Home
                    </Paragraph>
                    <Paragraph onClick={handleAboutUsClick} cursor={'pointer'} color="#02271480" fontSize="18px" margin="auto 32px">
                        About us
                    </Paragraph>
                    <Paragraph onClick={handleProductsClick} cursor={'pointer'} color="#02271480" fontSize="18px" margin="auto 32px">
                        Products
                    </Paragraph>
                    <Paragraph onClick={handleHowItWorksClick} cursor={'pointer'} color="#02271480" fontSize="18px" margin="auto 32px">
                        How it works
                    </Paragraph>
                    <Paragraph onClick={() => navigate('/blog')} cursor={'pointer'} color="#022714" fontSize="18px" margin="auto 32px">
                        Blog
                    </Paragraph>
                </Row>
                <Row>
                    <CustomLink
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        to="/login"
                    >
                        <Paragraph color="#022714" fontSize="18px" margin="auto 32px">
                            Login
                        </Paragraph>
                    </CustomLink>

                    <CustomLink
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        to="/signup"
                    >
                        <Button
                            color="#00A652"
                            width="104px"
                            height="48px"
                            borderRadius="8px"
                        >
                            Sign Up
                        </Button>
                    </CustomLink>
                </Row>
            </NavBar>

            {
                isBlogLoading
                    ?
                    <LoadingSpinner />
                    :   !Boolean(blog)
                        ?   <div style={{ width: '100%', maxWidth: '1300px', textAlign: 'center' }}>
                                <h1 style={{ fontWeight: '700', fontSize: '40px', marginTop: '50px', marginBottom: '0px' }}>Blog not Found</h1>
                            </div>
                        :   <div style={{ width: "100%", maxWidth: "1300px" }}>
                                <div style={{ width: '100%', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '76px' }}>
                                    {Boolean(blog?.picture)
                                        ?   <div style={{ width: '100%', height: '100%',borderRadius: '5px', overflow: 'hidden' }}>
                                                <img src={blog?.picture} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                                            </div>
                                        :   Boolean(blog?.video)
                                            ?   <div style={{ width: '100%', height: '100%', borderRadius: '5px', overflow: 'hidden' }}>
                                                    <video
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        src={blog?.video}
                                                        controls={true}
                                                        autoPlay={false}
                                                    />
                                                </div>
                                            :   <div style={{ width: '100%', height: '100%', borderRadius: '5px', overflow: 'hidden' }}>
                                                    <img src={blogPlaceholder} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                                                </div>
                                    }
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginTop: '35px' }}>
                                    <div style={{ display: "flex", alignItems: 'center', gap: "5px", font: "400 20px/28px TT Commons" }}>
                                        <Calender /> <span style={{ color: 'rgba(0,0,0,0.5)' }}>{moment(blog?.created_at)?.format('L')}</span>
                                    </div>
                                    {Boolean(blog?.category?.length) &&
                                        <div style={{ display: "flex", gap: "10px" }}>
                                            {blog?.category.split(',').map(c => (
                                                <span style={{ borderRadius: "2px", padding: "5px 8px", backgroundColor: "#00A65221", font: "400 15px/15px TT Commons" }}>{c}</span>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div style={{ width: '100%', maxWidth: '1300px' }}>
                                    <h2 style={{ margin: "36px 0 0",font: "600 48px/60px Montserrat", color: "#022714" }}>{blog?.headline}</h2>
                                    {Boolean(blog?.video && Boolean(blog?.picture)) &&
                                        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '25px' }}>
                                            <div style={{ width: '100%', height: '500px', maxWidth: '1300px', borderRadius: '5px', overflow: 'hidden' }}>
                                                <video
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    src={blog?.video}
                                                    controls={true}
                                                    autoPlay={false}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <p style={{ marginTop: "25px",font: "400 20px/26px Montserrat" }}>{blog?.text}</p>
                                </div>
                            </div>
            }

            <BottomSection />
            <Footer>©2022 All Rights Reserved by S & S Vault</Footer>
        </Page>
    )
}
export default ViewBlog