import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Paragraph, Title } from "../../common"
import Ellipse1 from "../Ellipse1"
import Ellipse2 from "../Ellipse2"
import Images from "./Images"
import Section from "./Section"

export default function ProductsSection() {
  const navigate = useNavigate()

  return (
    <Section>
      <Ellipse1 />
      <Ellipse2 />

      <Title fontSize="48px" margin="0 0 16px 0">
        Create your own space and <br /> manage files easily
      </Title>
      <Paragraph width="858px" display="block" margin="0 0 16px 0">
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that it has a more-or-less normal distribution of
        letters, as opposed to using 'Content here, content here', making it
        look like readable English. Many desktop publishing packages and web
        page.It is a long established fact that a reader will be distracted by
        the readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that it has a more-or-less normal distribution of
        letters, as opposed to using 'Content here, content here', making it
        look like readable English. Many desktop publishing packages and web
        page.
      </Paragraph>
      <Button
        border
        color="transparent"
        textColor="#000"
        borderRadius="10px"
        width="271px"
        height="56px"
        margin="0 0 54px 0"
        onClick={() => navigate("/signup")}
      >
        Create Store and Share Vault
      </Button>
      <Images />
    </Section>
  )
}
