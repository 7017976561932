import styled from "styled-components"

const MenuItem = styled.div`
  background-color: ${props => props.disabled ? "lightgray" : "transparent"};
  opacity: ${props => props.disabled ? 0.5 : 1};
  width: ${props => props.width};
  height: 100%;
  
  padding: 10px;

  font-family: "TT Commons";
  font-size: 14px;
  text-align: left;
  color: #000;

  :hover {
    background-color: ${props => props.disabled ? "lightgray" : "#ebebeb6b"};
  }
`

export default MenuItem
