import styled from "styled-components"

const PlanPrice = styled.p`
  font-family: "TT Commons";
  font-size: 36px;
  font-weight: 700;
  color: #000;
  margin: 4px;
`

export default PlanPrice
